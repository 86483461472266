<template>
	<div class="PageBox">
		<!-- title -->
		<div class="bgc">
			<div class="topTitle">{{$t('chatPage.default[0]',{name:user.username})}}</div>
		</div>
		<!-- 内容 -->
		<div class="query_box">
			<div class="query_content">
				<template v-for="(item,index) in messageList">
					<!-- 自己的话 -->
					<div class="right_item" v-if="item.user_id!=user.user_id">
						<div class="right_item_content">{{item.message}}</div>
						<div class="img_box">
							<img v-if="UserInfo" class="head" :src="UserInfo.header.includes('head_')?`./static/head/${UserInfo.header}`:UserInfo.header">
						</div>
					</div>
					<!-- 打招呼用户的话(对方) -->
					<div class="left_item" v-if="item.user_id == user.user_id">
						<div class="img_box">
							<img :src="user.image"/>
						</div>
						<div class="left_item_content">{{item.message}}</div>
					</div>
				</template>
			</div>
		</div>
		<!-- 发送对话 -->
		<div class="inputBox">
			<input v-model="inputContent" />
			<button @click="setRobotSend_message()">{{$t('chatPage.default[1]')}}</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'chatPage',
		components: {

		},
		data() {
			return {
				messageList: [
					// {
					// 	type: 0,msg: "押金是什么？"
					// },
					// {
					// 	type: 1,msg: "押金是你给平台的保证金，不同的保证金可做不同的任务，押金越多，每天可做的任务越多。"
					// },
					// {
					// 	type: 0,msg: "为什么要缴纳押金呢？"
					// },
					// {
					// 	type: 1,msg: "为了防止有用户恶意做任务，押金是为维护平台与用户共同利益而产生的一个保证金。"
					// },
					// {
					// 	type: 0,msg: "那我的押金要如何提取？"
					// },
					// {
					// 	type: 1,msg: "押金是可以和你做任务所赚的钱，随时提取的。温馨提示，为了不过多的产生手续费，建议您可以把押金一直放在平台中，每天做任务则可。"
					// },
					// {
					// 	type: 1,msg: "押金是你给平台的保证金，不同的保证金可做不同的任务，押金越多，每天可做的任务越多。"
					// },
					// {
					// 	type: 0,msg: "为什么要缴纳押金呢？"
					// },
					// {
					// 	type: 1,msg: "为了防止有用户恶意做任务，押金是为维护平台与用户共同利益而产生的一个保证金。"
					// },
					// {
					// 	type: 0,msg: "那我的押金要如何提取？"
					// },
					// {
					// 	type: 1,msg: "押金是可以和你做任务所赚的钱，随时提取的。温馨提示，为了不过多的产生手续费，建议您可以把押金一直放在平台中，每天做任务则可。"
					// },
				], //消息list
				inputContent:"",//对话框内容
				user:{},//打招呼用户的信息
				my_to_user:''
				}
		},
		computed: {

		},
		created() {
			let {id}=this.$route.params
			this.my_to_user=id;
			// console.log(this.my_to_user)
			this.user=this.InitData.memberList.filter((item) =>item.user_id==id)
			this.user=this.user[0]

			this.$parent.navBarTitle = ''
			this.getRobotGet_message(this.user.user_id)
			console.log(this.messageList)
		},
		mounted() {

		},
		activated() {

		},
		destroyed() {

		},
		methods: {
			// 发送留言
			setRobotSend_message(){
				if(!this.inputContent){
					this.$Dialog.Toast("不能发送空消息");
					return;
				}
				let json={
					to_user_id:this.user.user_id,
					message:this.inputContent
				}
				// 请求接口
				this.$Model.RobotSend_message(json,data=>{
					
					if(data.code==1){
						let itemMsg={
							message:this.inputContent
						}
						console.log(itemMsg)
						// 解构添加刚才输入的对话
						this.messageList=[...this.messageList,itemMsg]
					}
					// 清空输入框
					this.inputContent=""
				})
			},
			getRobotGet_message(id){
				this.$Model.RobotGet_message({to_user_id:id},data=>{
					this.messageList=data.data.data;
					this.messageList = this.messageList.reverse()
					// console.log(this.messageList)
				})
			}
		}
	}
</script>

<style scoped>
	.PageBox {
		overflow: auto;
	}
	
	.PageBox .bgc {
		width: 100%;
		background-color: #4E51BF;
		height: 46px;
		position: fixed;
		top: 0;
		z-index: 200;
	}
	
	.topTitle {
		position: absolute;
		max-width: 60%;
		white-space: nowrap;
		font-size: 18px;
		color: #fff;
		background-color: transparent;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9999;
	}
	.query_box {
		background-color: #fff;
		padding: 10px 10px 60px 10px;
		/* border: 3px solid #f9e9aa; */
		box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
		overflow: auto;
		height: 100%;
	}
	
	.query_box_padding {
		padding: 95px 20px 0;
	}
	
	.query_box_title {
		width: 170px;
		min-width: 150px;
		padding: 3px 0;
		border-radius: 10px;
		text-align: center;
		border: 2px solid #f9e9aa;
		background: linear-gradient(11deg, #efcf7c, #ffe7a9, #f3d381);
		box-shadow: -2px 1px 0px 0px #c57e57;

		font-weight: normal;
		color: #292929;
		font-size: 15px;
		font-weight: bold;
		margin: 5px auto 34px;
	}
	
	.query_content {
		display: flex;
		flex-direction: column;
	}
	
	.query_content .left_item {
		display: flex;
		width: 100%;
		margin-bottom: 16px;
	}
	
	.query_content .left_item .img_box,
	.query_content .right_item .img_box {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background-color: #fff;
		overflow: hidden;
		border: 2px solid #4E51BF;
	}
	
	.query_content .left_item .img_box img,
	.query_content .right_item .img_box img {
		width: 100%;
	}
	
	.query_content .left_item .left_item_content {
		max-width: 260px;
		width: 57%;
		min-width: 150px;
		padding: 10px 15px;
		border-radius: 6px;
		background: #f1dcc1;

		color: #313131;
		font-size: 13px;
		display: flex;
		align-items: center;
		letter-spacing: 0.04rem;
		position: relative;
		margin-left: 13px;
	}
	
	.query_content .left_item .left_item_content::after {
		content: "";
		display: block;
		position: absolute;
		left: -8px;
		top: 20px;
		width: 10px;
		height: 10px;
		background: url(../../../public/static/images/vip_content_item_angle.png) no-repeat;
		background-size: contain;
	}
	
	.query_content .right_item {
		display: flex;
		width: 100%;
		margin-bottom: 16px;
		justify-content: flex-end;
	}
	
	.query_content .right_item .right_item_content {
		max-width: 260px;
		width: 57%;
		min-width: 150px;
		padding: 10px 15px;
		border-radius: 6px;
		background: #dcdcdc;

		color: #313131;
		font-size: 13px;
		display: flex;
		align-items: center;
		letter-spacing: 0.04rem;
		position: relative;
		margin-right: 13px;
	}
	
	.query_content .right_item .right_item_content::after {
		content: "";
		display: block;
		position: absolute;
		right: -9px;
		top: 20px;
		width: 10px;
		height: 10px;
		background: url(../../../public/static/images/vip_content_item_angle_right.png) no-repeat;
		background-size: contain;
	}
	
	.query_box .more {
		padding: 5px 20px;
		border-radius: 15px;
		background: #f9e9aa;
		font-size: 13px;
		color: #2f2f2f;
		margin: 20px auto 100px;
		width: max-content;

	}
	.inputBox{
		width: 100%;
		position: fixed;
		bottom: 0;
		height: 50px;
		box-sizing: border-box;
		padding:8px 8px;
		background-color: #f7f7f7;
		display: flex;
	}
	.inputBox input{
		flex: 1;
		margin-right: 8px;
		border: none;
		border-radius: 2px;
		background-color: #fff;
		padding:0 5px;
		font-size: 16px;
	}
	.inputBox button{
		font-size: 16px;
		color:#fff;
		background-color: #4E51BF;
		border-radius: 6px;
		border: none;
		width:60px;
	}
</style>
